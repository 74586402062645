<template>
  <div class="zygk-content">
    <div class="zygkInfor center">
      <bread-crumb v-if="isShowBread"></bread-crumb>
      <div class="title">
        <p>资源概况</p>
      </div>
      <div class="con-detail">
        <div class="pic-box">
          <img src="../../assets/images/kpzs/pic-zygk.png"/>
        </div>
        <div class="text-box" v-html="tpData.gs">
        </div>
      </div>
    </div>
  </div>

</template>
<script>
    import breadCrumb from '@/components/common/breadCrumb';
    import {getDyOneInfoByDyId} from "@/api/dyInfoService";
   
    export default {
        components: {
            breadCrumb
        },
      props: {
        zrzyDyId: {
          type: String,
          default: () => ''
        },
      },
        data() {
            return {
              tpData:{},
              isShowBread:false,
            }
        },
        mounted() {
          let zrzyDyId = this.$route.query.zrzyDyId ? this.$route.query.zrzyDyId : this.zrzyDyId;
          getDyOneInfoByDyId(zrzyDyId, null).then(response => {
            this.tpData = response.data
          })
        },
        methods: {

        }
    }
</script>

<style lang="less">
.zygkInfor {
  padding-bottom: 89px;

  .title {
    padding: 17px 0px 20px 0px;

    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
  }

  .con-detail {
    width: 100%;
    background: #FFFFFF;
    min-height: 300px;
    padding: 40px 0px;
    box-sizing: border-box;

    .pic-box {
      width: 100%;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text-box {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        padding: 18px 0px;
        text-align: justify;
      }
    }
  }
}
</style>

